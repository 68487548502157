import React from "react";


function Hero({title,videoId,headerBackground}) {

	return (
		<header className="master-header video-header">
			{headerBackground !== false && videoId !== false ? (
				<>
					<img  src={`/rings.svg`} alt="loader" className="loader" />
					<iframe src={'https://player.vimeo.com/video/' + videoId + '?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" class="fade-in'}></iframe>
					{title &&
						<div className="container">
							<div className="page-header">
								<h1 className="page-title">{title}</h1>
							</div>
						</div>
					}
				</>
			) : (
				null
			)}
		</header>
	);
}

export default Hero;
