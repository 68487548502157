import React ,{ Component } from 'react';
import { graphql, Link } from "gatsby"
import Layout  from '../components/layout.js';
import Hero  from '../components/hero.js';
import Seo from "../components/seo"
import LeadershipCard from '../components/leadershipCard.js';
import HeroTitle  from '../components/hero-title/hero-title';

import $ from 'jquery'



class LeadershipPageTemplate extends Component {
	componentDidMount() {
		if (window.location.pathname.indexOf('/about/') >= 0) {
			$('.header-links .header-link[href="/about/"]').addClass('active')
		}
	}

	render() {
		const currentPage = this.props.data.wpPage
		const aboutSubmenu = this.props.data.wpMenu
		const allMembers = this.props.data.allWpLeadershipMember
		const membersTax = this.props.data.allWpMembers
		return (
			<Layout>
				<Seo
					title={currentPage.title}
					contentBody={currentPage.body}
					locationOrigin={this.props.location.origin + currentPage.uri}
					domen={this.props.location.origin}
					image={currentPage.featuredImage ?  currentPage.featuredImage.node.sourceUrl.replace(process.env.WP_SITE_URL,"/") : null}
				/>
				{currentPage.slug === 'about' || (currentPage.wpParent && currentPage.wpParent.node.slug === 'about') ? (
					<div className="sub-menu">
						<div className="container">
							<ul>
								{aboutSubmenu.menuItems.nodes.map(item => (
									<li key={item.url}>
										<Link className={currentPage.uri === item.url ? ( 'active' ) : ( null )} to={item.url}>{item.label}</Link>
									</li>
								))}
							</ul>
						</div>
					</div>
				) : (
					null
				) }
				<Hero videoId={currentPage.headerBackground.vimeoId} headerBackground={currentPage.headerBackground}/>
				{currentPage?.title &&
					<HeroTitle title={currentPage?.title} />
				}
				<section className="leadership-bck">
					<div className="container">
						{membersTax.nodes.map((taxonomy,index) => (
							<div key={index}>
								<h3 className="h3 leader-taxonomy">{taxonomy.name}</h3>
								<div className="uk-child-width-1-2@s uk-child-width-1-3@l all-cards-wrapper uk-grid uk-grid-small" data-uk-grid>
								{allMembers.nodes.map((lider,index) => (
									lider.members.nodes[0] && taxonomy.name === lider.members.nodes[0].name && lider.status === 'publish' ?
										<LeadershipCard
											key={index}
											name={lider.title}
											title={lider.leadershipMembers.titles}
											slug={lider.slug}
											image={lider.leadershipMembers.image ? lider.leadershipMembers.image.sourceUrl.replace(process.env.WP_SITE_URL,"/") : null}
										 /> : null
									))}
								</div>
							</div>
						))}
						<p dangerouslySetInnerHTML={{__html: currentPage.content}} />
					</div>
				</section>
			</Layout>
		)
	}
}

export default LeadershipPageTemplate;

export const data = graphql`
	query($id: String!) {
		wpPage(id: { eq: $id }) {
			uri
			title
			slug
			status
			content
			featuredImage {
				node {
					sourceUrl
				}
			}
			headerBackground {
				vimeoId
				videoPoster {
					sourceUrl
				}
			}
			wpParent {
				node {
					slug
				}
			}
		}

		site {
			id
			siteMetadata {
				title
			}
		}

		allWpLeadershipMember(sort: {fields: leadershipMembers___itemOrder, order: ASC}) {
			nodes {
				status
				slug
				title
				leadershipMembers {
					titles
					image {
						sourceUrl
						fileSize
						sizes
					}
				}
				members {
					nodes {
						name
					}
				}
			}
		}
		wpMenu(name: {eq: "About-submenu"}) {
			menuItems {
				nodes {
					url
					label
				}
			}
		}

		allWpMembers(sort: {fields: databaseId, order: DESC}) {
			nodes {
				name
			}
		}
	}`