import React from "react";
import { Link } from "gatsby";
import "../styles/scss/_leadershipCard.scss";

function LeadershipCard({name,title,image,size,slug}) {

	return (
		<div className="leadership-card-wrapper">
			<div className="uk-grid uk-grid-small" data-uk-grid>
				{image ?
					<div className="uk-width-1-2">
					<div className="image-wraper-leader" style={{backgroundImage: `url(${image})`}}></div>
					</div>
					:
					<div className="uk-width-1-2">
					<div className="image-wraper-leader"></div>
					</div>
				}
				<div className="uk-width-1-2 uk-card">
					<div className="info-box">
						<h3 className="h2 leader-name">{name}</h3>
						<div className="leader-title">{title}</div>
						<Link to={`/leadership-members/${slug}`} className="link-text">VIEW PROFILE</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LeadershipCard;
